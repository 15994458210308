import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    DatePicker,
    Paginate
  },
  data: function() {
    return {
      busy: false,
      date_start: null,
      date_end: null,
      sale_date_start: null,
      sale_date_end: null,
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      status: null,
      orderStatus: [
        { text: 'On hold', value: 'onhold' },
        { text: 'รออนุมัติ', value: 'wait-approve' },
        { text: 'อนุมัติแล้ว', value: 'approved' },
        { text: 'ไม่อนุมัติ', value: 'not-approved' },
        { text: 'สำเร็จ', value: 'success' },
        { text: 'ยกเลิก', value: 'cancel' }
      ],
      fields: [
        { 
          key: 'doc_no', 
          label: 'เลขที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'doc_date', 
          label: 'วันที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'doc_so', 
          label: 'เลขที่ใบสั่งขาย', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'date_so', 
          label: 'วันที่ใบสั่งขาย', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'emp', 
          label: 'พนักงาน', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'approver', 
          label: 'ผู้อนุมัติ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'qty', 
          label: 'จำนวน', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'status', 
          label: 'สถานะ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        }
      ],
      items: [
        {
          _id: '123456',
          doc_no: 'WD-201665156',
          doc_date: new Date(),
          doc_so: 'SO-201665156',
          date_so: new Date(),
          emp: 'ไพศาล',
          approver: 'สมชาย',
          qty: 2,
          status: '<label class="badge badge-info">On hold</label>'
        }
      ]
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    onInitData() {
      try {
        const item = this.items
        this.items = []
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'
        
        setTimeout(() => {
          this.busy = false
          this.apiStatus = 'ไม่พบข้อมูล'
          
          this.items = item
        }, 1000)
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}